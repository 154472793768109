var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main",class:_vm.isWx ? 'main_wx' : '',style:(!_vm.isIos && !_vm.isAndroid && !_vm.isWx ? 'max-width: 500px; margin: 0 auto;' : '')},[_c('div',{staticStyle:{"position":"absolute","top":"0","left":"0","padding":"12px 12px","box-sizing":"border-box","display":"flex"}},[_vm._m(0),_c('div',{staticStyle:{"flex":"auto"}},[_c('div',{staticStyle:{"font-size":"10px","font-weight":"400","color":"#FFFFFF","line-height":"16px"}},[_vm._v("开发者信息：上海众旦信息科技有限公司"),_c('br'),_vm._v(" 应用版本："+_vm._s(_vm.appVersion)),_c('br'),_vm._v(" 应用涉及"),_c('a',{staticStyle:{"text-decoration":"none","color":"#fff"},attrs:{"href":_vm.userProtocol}},[_vm._v("《用户使用协议》")]),_vm._v("、"),_c('a',{staticStyle:{"text-decoration":"none","color":"#fff"},attrs:{"href":_vm.privacyProtocol}},[_vm._v("《隐私政策》")]),_vm._v("及"),_c('a',{staticStyle:{"text-decoration":"none","color":"#fff"},attrs:{"href":_vm.appAuthProtocol}},[_vm._v("《应用权限》")])])])]),_c('img',{attrs:{"src":require(("" + (_vm.isXapp
          ? './assets/image/x/header.png'
          : './assets/image/akc/1.jpg')))}}),_c('img',{attrs:{"src":require(("" + (_vm.isXapp
          ? './assets/image/x/select.png'
        : './assets/image/akc/2.jpg')))}}),_c('div',{staticClass:"button_wrap",style:(!_vm.isIos && !_vm.isAndroid && !_vm.isWx
        ? 'width: 500px; margin: 0 auto; height:80px; padding: 16px 0; box-shadow:0px -1px 6px 0px rgba(213,213,213,0.3);'
        : '')},[_c('div',{staticClass:"open_app",style:(!_vm.isIos && !_vm.isAndroid && !_vm.isWx
          ? 'font-size: 16px; height:48px;  border-radius:24px; line-height: 44px; left: 16px;'
          : ''),on:{"click":function($event){return _vm.getUrl('click')}}},[_vm._v(" 我已下载 立即打开 ")]),_c('div',{staticClass:"download_app",style:(!_vm.isIos && !_vm.isAndroid && !_vm.isWx
          ? 'font-size: 16px;  height:48px; border-radius:24px; line-height: 48px;  right: 16px;'
          : ''),on:{"click":function($event){return _vm.downloadNow()}}},[_vm._v(" 立即下载 ")])]),(_vm.isWx)?_c('div',{staticClass:"guide"},[_c('div',{staticClass:"arrow"}),_vm._m(1)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"flex":"none","padding-right":"9px"}},[_c('img',{staticStyle:{"width":"80px","height":"25px"},attrs:{"src":require("./assets/image/logo.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text"},[_c('p',[_vm._v("点击右上角")]),_c('p',[_vm._v("请选择在"),_c('i'),_vm._v("(浏览器)打开")])])}]

export { render, staticRenderFns }