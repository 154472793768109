<template>
  <div class="page-wrap">
    <img src="@/assets/image/xpp-bg.png" />
    <div class="button_wrap">
      <div class="download_app" @click="goApp()">
        <span>立即下载</span>
      </div>
    </div>
    <div v-if="isWx" class="guide">
      <div class="arrow"></div>
      <div class="text">
        <p>点击右上角</p>
        <p>请选择在<i></i>(浏览器)打开</p>
      </div>
    </div>
  </div>
</template>
<script>
import { isIos, isAndroid, isWxBroser, downloadApp } from "@/assets/js/util";
import Track from "@/assets/js/track";

export default {
  name: "guideDownApp",
  data() {
    return {
      isWx: false,
      isIos,
      isAndroid,
    };
  },
  created() {
    Track.sendLog("pageview", {
      spm: "4.637",
      currentProperties: {
        fromPage: "guideDownApp短信短链",
      },
    });
    this.isWx = isWxBroser;
    document.title = "饷App下载页";
  },
  methods: {
    goApp() {
      downloadApp();
    },
  },
};
</script>
<style scoped lang="scss">
.page-wrap {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  min-height: 757px;
  background-color: #fff;
  // background-image: url(../assets/image/xpp-bg.png);
  // background-repeat: no-repeat;
  // background-size: 375px 667px;
  img {
    display: block;
    border: 0;
    width: 375px;
    height: 667px;
  }
  .download_app {
    position: fixed;
    bottom: 36px;
    left: 10%;
    width: 80%;
    height: 44px;
    background: linear-gradient(135deg, #fb0515 0%, #f90280 100%);
    border-radius: 22px;
    text-align: center;
    line-height: 44px;
    font-size: 16px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #ffffff;
  }
  .guide {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.5);
    .arrow {
      width: 55px;
      height: 51px;
      position: absolute;
      top: 10px;
      right: 26px;
      background-image: url("../assets/image/guide.png");
      background-size: 100% 100%;
    }
    .text {
      width: 144px;
      height: 40px;
      position: absolute;
      top: 62px;
      right: 13px;
      p {
        line-height: 20px;
        text-align: center;
        font-size: 13px;
        color: #fff;
        position: relative;
        i {
          width: 12px;
          height: 12px;
          position: relative;
          top: 2.5px;
          display: inline-block;
          background-image: url("../assets/image/akc/global.png");
          background-size: 100% 100%;
        }
      }
    }
  }
}
</style>
